import React from 'react'
// import { Link } from "gatsby"
import { connect } from 'react-redux'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Intro from '../components/sections/intro'
import Partners from '../components/sections/partners'
import Main from '../components/apply/main'
import Header from '../components/header'

import {
  changeType,
  toggleBypassZip,
  toggleIncludeAddress,
  toggleField,
  toggleIncludeExisting,
  goToStep,
} from '../reducers/captive'

const ApplyPage = ({
  location,
  toggleField,
  toggleBypassZip,
  toggleIncludeExisting,
  toggleIncludeAddress,
  goToStep,
  changeType,
}) => {
  const isR4M = true
  React.useLayoutEffect(() => {
    toggleBypassZip(true)
    toggleField('homeOrigin', 'research-for-me')
    toggleIncludeExisting(true)
    toggleIncludeAddress(true)
    changeType('medicareAlt')
    goToStep('dobmonth')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    window?.ga &&
      window.ga('send', 'screenview', {
        appName: 'medicarecompanion.org',
        screenName: 'enroll',
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className={isR4M ? 'inter-font research-for-me' : ''}>
      <Layout phone="866-740-2901" location={location}>
        <SEO
          title="Home"
          keywords={[`medicare`, `insurance`, `plans`, `affordable`, `supplement`]}
        />
        <Header phone="866-740-2901" isR4M={isR4M} />
        <Intro showTitle={false} colType="full-col">
          <Main location={location} startStep="dobmonth" />
        </Intro>
        <main id="main">
          <Partners />
        </main>
      </Layout>
    </div>
  )
}

const mapState = (state) => ({
  homeOrigin: state.captive.homeOrigin,
})

export default connect(mapState, {
  toggleField,
  toggleIncludeExisting,
  toggleIncludeAddress,
  toggleBypassZip,
  changeType,
  goToStep,
})(ApplyPage)
